import React from 'react'
import AwardCard from './awardcard'

import SectionCaption from '../layouts/sectioncaption.js'
import './awards.css'
import '../pages/base.css'

class Awards extends React.Component {
    constructor(props){
        super(props);

        let countOnePage = 6;

        if(this.props.data.length % countOnePage != 0) {
            let awards_gap_group = this.props.data.slice(0,  countOnePage - (this.props.data.length % countOnePage));
            for(let index = 0; index < awards_gap_group.length; index++) {
                this.props.data.push(awards_gap_group[index])
            }
        }

        this.state = {
            firstIndexOnShow: 0,
            maxNumOnePage: countOnePage,
            maxAwardsNum: this.props.data.length,
            awards: this.props.data.slice(0, countOnePage),
        };
    }

    componentDidMount() {
        document.getElementById("left-arrow").addEventListener("click", this.leftClickCallback);
        document.getElementById("right-arrow").addEventListener("click", this.rightClickCallback);
    }

    leftClickCallback = () => {
        if(this.state.firstIndexOnShow<=0){ 
            this.noMoreCallBack("left-arrow");
            return; 
        }

        this.setState({
            firstIndexOnShow: this.state.firstIndexOnShow-this.state.maxNumOnePage
        });

        this.updateAwardsList();
    }

    rightClickCallback = () => {
        if(this.state.firstIndexOnShow+this.state.maxNumOnePage >= this.state.maxAwardsNum) { 
            this.noMoreCallBack("right-arrow");
            return; 
        }

        this.setState({
            firstIndexOnShow: this.state.firstIndexOnShow + this.state.maxNumOnePage
        });

        this.updateAwardsList();
    }

    updateAwardsList() {
        document.querySelectorAll(".AwardCard").forEach((elem) => {
            elem.style.animation = "blurAnimation 1s";
            elem.addEventListener("animationend", () => {
                elem.style.animation = "";
            })
        });
        setTimeout(() => {
            this.setState({ 
                awards: this.props.data.slice(this.state.firstIndexOnShow, this.state.firstIndexOnShow + this.state.maxNumOnePage) 
            });
        }, 500);
    }

    noMoreCallBack(id){
        var arrow = document.getElementById(id);
        arrow.style.animation = "noMoreAnimation 1s";
        arrow.addEventListener("animationend", () => {
            arrow.style.animation = "";
        });
    }

    render() {
        return (
            <div className="Awards">
                <SectionCaption
                    title="但行耕耘"
                    emoji="🏆"
                    subtitle="让他着迷的是结果之外的一切"
                />
                <div className="AwardDisplayArea">
                    <div id="left-arrow" className="arrow left">&lt;</div>
                    <div className="AwardCardGroup">
                        {this.state.awards.map((edge, index) => (
                            <AwardCard
                                key={index}
                                name={edge.node.name}
                                prize={edge.node.prize}
                                time={edge.node.time}
                                certificate={edge.node.certificate.fluid.src}
                            />
                        ))}
                    </div>
                    <div id="right-arrow" className="arrow right">&gt;</div>
                </div>
            </div>
        )
    }
}

export default Awards