import React from 'react'
import { divide } from 'lodash'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import './blogs.css'
import ArticlePreview from '../templates/artical-preview.js'

class Blogs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstElementIsIn: false,
            firstElementIsOut: false,
            lastElementIsIn: false,
            lastElementIsOut: false
        }

    }

    render() {
        return (
            <div className="BlogsGroup">
                <ul className="article-list">
                    {this.props.data.map(({ node }) => {
                        return (
                        <li key={node.slug} className="non-full-view">
                            <ArticlePreview article={node} />
                        </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default Blogs