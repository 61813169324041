import React from 'react'

import './forthetime.css'
import SectionCaption from '../layouts/sectioncaption'
import GithubChart from '../layouts/githubchart'

const ForTheTime = () => (
    <div className="ForTheTime">
        <SectionCaption
            title="给时间"
            emoji="🕙"
            subtitle="时间比我能告诉你的更多"
            data-spotlight="给时间"
        />
        <GithubChart />
    </div>
)

export default ForTheTime