import React from 'react'
import Img from 'gatsby-image'

import TagCard from './tagcard.js'
import SectionCaption from '../layouts/sectioncaption'
import '../pages/base.css'
import './tags.css'

const Tags = ({ data }) => (
    <div className="Tags">
        <SectionCaption
            title="人生轨迹"
            emoji="⏳"
            subtitle="人生是个不断贴标签的过程"
         />
         <div className="TagGroup">
            {data.map((edge, index) => (
                <TagCard
                    key={index}
                    name={edge.node.name}
                    description={edge.node.description}
                    logo={edge.node.logo.fluid.src}
                />
            ))}
         </div>
    </div>
)

export default Tags