import React from 'react'

import './tagcard.css'

const TagCard = data => (
    <div className='TagCard'>
        <div className='TagCard-img-container'>
            <img src={data.logo} draggable="false" />
        </div>
        <div className='Text'>
            <h1>#{data.name}</h1>
            <p>{data.description}</p>
        </div>
    </div>
)

export default TagCard