import React, {Component} from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import ReactDOM from 'react-dom';

import 'lazysizes'

import './base.css'
import SectionCaption from '../layouts/sectioncaption'
import Wave from '../components/wave'
import Layout from '../layouts/layout'
import Hero from '../components/hero'
import Awards from '../components/awards'
import Tags from '../components/tags'
import WaveSection from '../layouts/wavesection'
import ForTheTime from '../components/forthetime'
import Blogs from '../components/blogs.js'
import ToTop from '../layouts/totop.js'
import Gallery from '../components/gallery.js'

class RootIndex extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      child: [],
      totalNum: 4,
      wave_section_data: [],
      backgrounds: get(this, 'props.data.allContentfulBackground.edges'),
      mottos: get(this, 'props.data.allContentfulMotto.edges'),
      avatars: get(this, 'props.data.allContentfulAvatar.edges'),
    };


    this.state.wave_section_data = [];
    for(var i=0; i < this.state.totalNum; ++i){
      var randomData = {
        image: this.state.backgrounds[this.handleRandomNum(this.state.backgrounds.length-1)].node.bg.fluid.src,
        logo: this.state.avatars[this.handleRandomNum(this.state.avatars.length-1)].node.img.fluid.src,
        text: this.state.mottos[this.handleRandomNum(this.state.mottos.length-1)].node.text
      };
      this.setState({ wave_section_data: this.state.wave_section_data.push(randomData) });
    }

  }

  componentDidMount(){
    this.timerID = setInterval(() => {
      this.handleRandomWaveSection();
    }, 20000);
  }

  componentWillUnmount(){
    clearInterval(this.timerID);
  }

  handleRandomWaveSection = () => {
    this.state.wave_section_data = [];
    for(var i=0; i < this.state.totalNum; ++i){
      var randomData = {
        image: this.state.backgrounds[this.handleRandomNum(this.state.backgrounds.length-1)].node.bg.fluid.src,
        logo: this.state.avatars[this.handleRandomNum(this.state.avatars.length-1)].node.img.fluid.src,
        text: this.state.mottos[this.handleRandomNum(this.state.mottos.length-1)].node.text
      };
      this.state.wave_section_data[i] = randomData;
    }

    this.setState({ wave_section_data: this.state.wave_section_data });   //要用setState才会将动态绑定的数据刷新

    if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))){
      this.state.child.map(e => {
        e.handleTyping()
      });
    }
  }

  handleRandomNum = (length) => {
    return parseInt(Math.random() * ( length + 1 ), 10); 
  }

  onWaveSectionChange = (ref) => {
    this.setState({ child: [...this.state.child, ref] });
  }

  render() {
    console.log("将要面对的与已成过往的，较之深埋内心的，皆为微末。")
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const heros = get(this, 'props.data.allContentfulHero.edges')
    const awards = get(this, 'props.data.allContentfulAward.edges')
    const tags = get(this, 'props.data.allContentfulTag.edges')

    const socialmedias = get(this, 'props.data.allContentfulSocialMedia.edges')
    const blogposts = get(this, 'props.data.allContentfulBlogPost.edges')

    const photogallerys = get(this, 'props.data.allContentfulPhotoGallery.edges')

    return (
      <Layout location={this.props.location} socialmedias={socialmedias}>

        <Helmet title={siteTitle} />
        <ToTop />

        <Hero data={heros} />

        <a className="NavigatorAnchor" id="life-trace" name="life-trace">life-trace</a>
        <Tags data={tags} />

        <a className="NavigatorAnchor" id="for-the-time">for-the-time</a>
        <ForTheTime />

        <WaveSection 
            onWaveSectionChange={this.onWaveSectionChange}
            image={this.state.wave_section_data[0].image}
            logo={this.state.wave_section_data[0].logo}
            text={this.state.wave_section_data[0].text}
        />

        <a className="NavigatorAnchor" id="life-award" name="life-award">life-award</a>
        <Awards data={awards} />
        
        <WaveSection 
            onWaveSectionChange={this.onWaveSectionChange}
            image={this.state.wave_section_data[1].image}
            logo={this.state.wave_section_data[1].logo}
            text={this.state.wave_section_data[1].text}
        />

        <a className="NavigatorAnchor" id="better-study" name="better-study">better-study</a>
        <SectionCaption
            title="好好学习"
            emoji="🎓"
            subtitle="将来的你会感谢现在拼命的自己"
        />
        <Blogs data={blogposts} />


        <WaveSection 
          onWaveSectionChange={this.onWaveSectionChange}
          image={this.state.wave_section_data[2].image}
          logo={this.state.wave_section_data[2].logo}
          text={this.state.wave_section_data[2].text}
        />


        <a className="NavigatorAnchor" id="better-live" name="better-live">better-live</a>
        <SectionCaption
            title="好好生活"
            emoji="🎬"
            subtitle="生活其实很简单生出来活下去"
        />
        <Gallery data={photogallerys} avatars={this.state.avatars} />


        <WaveSection 
          onWaveSectionChange={this.onWaveSectionChange}
          image={this.state.wave_section_data[3].image}
          logo={this.state.wave_section_data[3].logo}
          text={this.state.wave_section_data[3].text}
        />
      

        {/* coming sooning......... */}          
        <SectionCaption
            title="再慢一点"
            emoji="💭"
            subtitle="来  归  去"
        />
        <p className="ComingSoon">慢 下 来...</p>

        <div className="WebsiteStatisticGroup">
          <a href="https://clustrmaps.com/site/1bib3" title="Visit tracker"><img src="//www.clustrmaps.com/map_v2.png?d=oqY3PVNWDkZtpjzA-vOFtXGn2Ip19TQtX7EW7e_6Mcw&cl=ffffff" /></a>
        </div>

        {/* <button onClick={window.location.href="/blogs"}></button> */}
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHero(sort: { fields: [createdAt], order: ASC }, filter: {page: {eq: "doubleZ"}}) {
      edges {
        node {
          name
          description
          quote
          bg {
            fluid(maxWidth: 1000){
              sizes
              src
              srcSet
            }
          }
          createdAt
          page
          color
        }
      }
    }
    allContentfulAward(sort: {fields: [bestIndex, orderRank, internalOrderRank, createdAt], order: [ASC, DESC, DESC, DESC]}) {
      edges {
        node {
          name
          prize
          certificate {
            fluid(maxWidth: 1000){
              sizes
              src
              srcSet
            }
          }
          time
          createdAt
          orderRank
          internalOrderRank
          bestIndex
        }
      }
    }
    allContentfulTag(sort: { fields: [orderRank, createdAt], order: ASC }) {
      edges {
        node {
          name
          description
          logo {
            fluid(maxWidth: 1000) {
              sizes
              src
              srcSet
            }
          }
          createdAt
          orderRank
        }
      }
    }
    allContentfulBackground(filter: {type: {eq: "Design+Code Mountain"}}) {
      edges {
        node {
          bg {
            fluid(maxWidth: 1000){
              sizes
              src
              srcSet
            }
          }
          type
          name
        }
      }
    }
    allContentfulMotto {
      edges {
        node {
          text
          author
        }
      }
    }
    allContentfulAvatar(filter: {who: {eq: "doubleZ"}}) {
      edges {
        node {
          who
          img {
            fluid(maxWidth: 1000){
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
    allContentfulSocialMedia (sort: { fields: [orderRank, createdAt], order: ASC }) {
      edges {
        node {
          fontclass
          color
          url
          title
          orderRank
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "YYYY-MM-DD")
          tags
          heroImage {
            fluid(maxWidth: 1000) {
              sizes
              src
              srcSet
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPhotoGallery(sort: { fields: [time], order: DESC },  filter: {withYoyo: {ne: true}}) {
      edges {
        node {
          topic
          text1
          text2
          text3
          textEn1
          textEn2
          textEn3
          album {
            fluid {
              src
            }
          }
          time
          withYoyo
        }
      }
    }
  }
`