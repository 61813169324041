import React from 'react'
import './awardcard.css'

const AwardCard = props => (
    <div className="AwardCard">
        <img src={props.certificate} draggable="false" loading="lazy" />
        <h2>{props.name}</h2>
        <div className="appidx-info">
            <p>{props.prize}</p>
            <div className="Time">{props.time}</div>
        </div>
        
    </div>
)

export default AwardCard
